import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import {
  useAddCasePlan,
  useGetPlans,
  useRemoveCasePlan,
} from "@/hooks/useEnrollments";
import { useParticipantById } from "@/hooks/useParticipants";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useUserStore } from "@/hooks/useUserStore";
import UploadButton from "@/components/UploadButton";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { CasePlan } from "@/domain/enrollment.interface";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import GenericAlertModal from "@/components/AlertModal";

interface CasePlanModalProps {
  open: boolean;
  onClose: () => void;
}

const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export default function CasePlanModal({ open, onClose }: CasePlanModalProps) {
  const theme = useAppTheme();
  const { userInfo } = useUserStore();
  const { participantId } = useParams();
  const createAuditLog = useCreateAuditlog();
  const { showSnackBar } = useFeedBack();

  const { data: participantResponse } = useParticipantById(participantId || "");
  const uploadCasePlan = useAddCasePlan();
  const deleteCasePlan = useRemoveCasePlan();
  const { casePlans, refetchCasePlans } = useGetPlans(
    participantResponse?.lastEnrollment?._id || ""
  );

  const [approveAlert, setApproveAlert] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
  }>({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });

  const generateUniqueFileName = (originalName: string): string => {
    const extension = originalName.slice(originalName.lastIndexOf("."));
    let baseName = originalName.slice(0, originalName.lastIndexOf("."));

    const match = baseName.match(/\((\d+)\)$/);
    if (match) {
      baseName = baseName.slice(0, baseName.lastIndexOf(" ("));
    }

    const existingFileNames = casePlans?.map((casePlan) => casePlan.fileName);
    let counter = 0;
    let newName = originalName;

    // Genera un nombre único
    while (existingFileNames?.includes(newName)) {
      counter++;
      newName = `${baseName} (${counter})${extension}`;
    }

    return newName;
  };

  const handleUpload = async (files: FileList | null): Promise<void> => {
    if (!files) return;

    const enrollmentId = participantResponse?.lastEnrollment?._id || "";

    const uploadSingleFile = async (file: File): Promise<void> => {
      const uniqueFileName = generateUniqueFileName(file.name);

      const renamedFile = new File([file], uniqueFileName, {
        type: file.type,
      });

      return uploadCasePlan.mutateAsync({
        enrollmentId,
        file: renamedFile,
      });
    };

    try {
      const filesToUpload = Array.from(files);

      for (const file of filesToUpload) {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          showSnackBar(
            `The file "${file.name}" exceeds the size limit of ${MAX_FILE_SIZE_MB} MB.`,
            "error"
          );
          return;
        }
      }

      const uploadPromises = filesToUpload.map((file) =>
        uploadSingleFile(file)
      );
      await Promise.all(uploadPromises);

      showSnackBar("Your case plan file(s) have been uploaded", "success");

      refetchCasePlans();
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.PARTICIPANTS_MANAGE_CASE_PLAN,
        option: OPTIONS.MANAGE_CASE_PLAN,
        actionCode: ACTION_CODE.WEB_PART_ADDED_CASE_PLAN,
        action: "When uploaded case plan",
        detail: `Added case plan to ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        programId: participantResponse?.lastEnrollment.programId as string,
        createdBy: userInfo?._id as string,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      showSnackBar("Failed to upload case plan file(s)", "error");
    }
  };

  const handleDelete = (casePlan: CasePlan): void => {
    setApproveAlert({
      open: false,
      title: "",
      message: "",
      onConfirm: () => ({}),
    });

    deleteCasePlan.mutate(
      {
        enrollmentId: participantResponse?.lastEnrollment?._id || "",
        casePlanId: casePlan._id,
      },
      {
        onSuccess: () => {
          refetchCasePlans();

          createAuditLog.mutate({
            appType: "WEB_BACK_OFFICE",
            module: MODULE_NAME.PARTICIPANTS_MANAGE_CASE_PLAN,
            option: OPTIONS.MANAGE_CASE_PLAN,
            actionCode: ACTION_CODE.WEB_PART_DELETED_CASE_PLAN,
            action: "When deleted a case plan file",
            detail: `Deleted the case plan file ${casePlan.fileName} from ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName} profile`,
            transactionDate: new Date(),
            accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
            programId: participantResponse?.lastEnrollment.programId as string,
            createdBy: userInfo?._id as string,
          });

          showSnackBar("The case plan file has been deleted.", "success");
        },
      }
    );
  };

  const auditLogViewCalledRef = React.useRef(false);
  useEffect(() => {
    if (userInfo && !auditLogViewCalledRef.current) {
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.PARTICIPANTS_MANAGE_CASE_PLAN,
        option: OPTIONS.MANAGE_CASE_PLAN,
        actionCode: ACTION_CODE.WEB_PART_ENTER_CASE_PLAN,
        action: "When opened popup case plan",
        detail: `Entered to view the case plan of ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        programId: participantResponse?.lastEnrollment.programId,
        createdBy: userInfo?._id as string,
      });
      auditLogViewCalledRef.current = true;
    }
  }, [userInfo]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        isPending={false}
        title="Case plan"
        theme={theme}
        size={{
          width: "100%",
          minHeight: 320,
          maxHeight: 480,
          maxWidth: 460,
        }}
        content={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "1.25rem",
            }}
          >
            <Grid container spacing={"20px"}>
              <Grid item xs={12} sm={12}>
                <Typography variant="headlineSmall" fontWeight={700}>
                  {participantResponse?.participant.firstName}{" "}
                  {participantResponse?.participant.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="bodyLarge">
                  Here you can upload/view the case plan of the client being
                  supervised. Remember that these files could be visible in the
                  mobile application of the client.
                </Typography>
              </Grid>
            </Grid>

            {casePlans && casePlans.length > 0 && (
              <List>
                {casePlans.map((casePlan) => (
                  <ListItem
                    key={casePlan._id}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: theme.palette.menu.dark,
                    }}
                  >
                    <Link
                      href={casePlan.url}
                      variant="titleSmall"
                      target="_blank"
                      sx={{
                        color: theme.palette.menu.dark,
                      }}
                    >
                      {casePlan.fileName}
                    </Link>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          border: `1px solid ${theme.palette.primary.dark}`,
                          height: "40px",
                          width: "40px",
                        }}
                        onClick={() => {
                          setApproveAlert({
                            open: true,
                            title: "Remove file",
                            message:
                              "Are you sure you want to remove this file? This action cannot be undone.",
                            onConfirm: () => handleDelete(casePlan),
                          });
                        }}
                      >
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: theme.palette.primary.dark }}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            )}

            <UploadButton
              label="Upload"
              onUpload={handleUpload}
              disabled={uploadCasePlan.isPending}
              isLoading={uploadCasePlan.isPending}
              accept=".pdf"
              multiple
              sx={{
                fontSize: "1rem",
              }}
            />
          </Box>
        }
      />

      {approveAlert.open ? (
        <GenericAlertModal
          onCancel={() =>
            setApproveAlert((prevState) => ({
              ...prevState,
              open: false,
            }))
          }
          onClick={() => {
            approveAlert.onConfirm();
          }}
          title={approveAlert.title}
          description={approveAlert.message}
          submitText="Accept"
          cancelText="Cancel"
          theme={theme}
        />
      ) : null}
    </>
  );
}
