import React, { FC, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "@/hooks/useDebounce";
import { useAppTheme } from "@/utils/theme";
import {
  useUserDeleteOne,
  useUserResendInvitation,
  useUsers,
} from "@/hooks/useUsers";
import DataTable, { createDataRow, HeadCell } from "@/components/DataTable";
import Chip from "@mui/material/Chip";
import { toTitleCase } from "@/utils/toTitleCase";
import UserInviteModal from "@/ui/modals/users/UserInviteModal";
import { UserType } from "@/domain/user.interface";
import ActionMenu from "@/components/ActionMenu";
import UserEditModal from "@/ui/modals/users/UserEditModal";
import EditIcon from "@mui/icons-material/Edit";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AuditLogModal from "@/ui/modals/AuditLogModal";
import { RoleSubtype } from "@/domain/roles.enum";
import { StatusType } from "@/domain/user.enum";
import { ErrorResponse } from "@/domain/error.interface";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import GenericAlertModal from "@/components/AlertModal";
import {
  useAuditLogFirstRender,
  useCreateAuditlog,
} from "@/hooks/useAuditLogs";
import { useUserStore } from "@/hooks/useUserStore";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import Button from "@mui/material/Button";
import { usePageStore } from "@/hooks/usePageStore";
import UserFilters, { Filters } from "@/ui/content/users/Filters";

const UsersPage: FC = () => {
  const theme = useAppTheme();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { showSnackBar } = useFeedBack();
  const { userInfo } = useUserStore();
  const { setPageTitle } = usePageStore();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType>();

  const [genericAlert, setGenericAlert] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => new Object(),
  });

  const userDeleteOne = useUserDeleteOne();
  const userResendInvitation = useUserResendInvitation();

  const filtersState = useState<Filters>({
    sortField: "fullName",
    sortOrder: 1,
    roleId: "",
    status: ["ACTIVE"],
    search: "",
    pageNumber: 0,
    pageSize: 10,
  });

  const [filters, setFilters] = filtersState;
  const debouncedInputValue = useDebounce({ value: filters.search });

  const { users, isLoadingUsers, refetchUsers } = useUsers({
    sortField: filters.sortField,
    sortOrder: filters.sortOrder,
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize,
    search: filters.search,
    status: filters.status,
    roleId: filters.roleId,
  });

  const handleResendInvitation = async (email: string) => {
    createAuditLog.mutate({
      appType: "WEB_BACK_OFFICE",
      module: MODULE_NAME.USERS,
      option: OPTIONS.RESEND_VERIFICATION_LINK,
      actionCode: ACTION_CODE.WEB_USRS_USR_RESEND_LINK,
      action: "When resending link",
      detail: `Resend verification link for ${email}`,
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
    });

    try {
      await userResendInvitation.mutateAsync(email, {
        onSuccess: () => {
          refetchUsers();
          showSnackBar("A new verification link has been sent to the user");
          setGenericAlert((prevState) => ({ ...prevState, open: false }));
        },
      });
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const handleDeleteUser = async (userId: string, email: string) => {
    createAuditLog.mutate({
      appType: "WEB_BACK_OFFICE",
      module: MODULE_NAME.USERS,
      option: OPTIONS.DELETE,
      actionCode: ACTION_CODE.WEB_USRS_USR_DELETE,
      action: "When deleting a user",
      detail: `Deleted the user ${email}`,
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
    });

    try {
      await userDeleteOne.mutateAsync(userId, {
        onSuccess: () => {
          refetchUsers();
          showSnackBar("The registry has been deleted.");
          setGenericAlert((prevState) => ({ ...prevState, open: false }));
        },
      });
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const rows = useMemo(() => {
    if (users && users.users.length === 0) return [];

    return (
      users?.users.map((user, index) => {
        const resendOption = user.status === StatusType.Pending && {
          value: "Resend invitation link",
          onClick: () => {
            setSelectedUser(user);
            setGenericAlert({
              open: true,
              title: "Resend invitation link",
              message: "Are you sure you want to resend the invitation link?",
              onConfirm: () => handleResendInvitation(user.email),
            });
          },
          icon: <MailOutlineIcon fontSize="small" />,
        };

        const actionMenu = [
          {
            value: "Edit",
            onClick: () => {
              setOpenEditModal(true);
              setSelectedUser(user);
            },
            icon: <EditIcon fontSize="small" />,
          },
          ...(resendOption ? [resendOption] : []),
          {
            value: "Delete",
            onClick: () => {
              setSelectedUser(user);
              setGenericAlert({
                open: true,
                title: "Delete",
                message: "Are you sure you want to delete this registry?",
                onConfirm: () => handleDeleteUser(user._id, `${user.email}`),
              });
            },
            icon: <DeleteOutlineIcon fontSize="small" />,
            sx: {
              color: theme.palette.error.main,
            },
          },
        ];
        const row = createDataRow(index, {
          user: <div>{`${user.firstName} ${user.lastName}`}</div>,
          role: <div>{user.roles[0]?.name}</div>,
          roleSubtype: user.roles[0]?.subType ? (
            <div>
              {RoleSubtype[user.roles[0]?.subType as keyof typeof RoleSubtype]}
            </div>
          ) : (
            <div>-</div>
          ),
          status: (
            <Chip
              sx={{
                bgcolor:
                  user.status === "ACTIVE"
                    ? theme.palette.brand.light
                    : theme.palette.warning.light,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor:
                  user.status === "ACTIVE"
                    ? theme.palette.brand.main
                    : theme.palette.warning.dark,
              }}
              label={<div className="text-xs">{toTitleCase(user.status)}</div>}
            />
          ),
          action: <ActionMenu id={user._id} options={actionMenu} />,
        });

        return row;
      }) || []
    );
  }, [users]);

  const headCells: HeadCell[] = [
    {
      id: "fullName",
      isSortable: true,
      disablePadding: true,
      label: "User",
      align: "left",
      width: "200px",
    },
    {
      id: "roles.name",
      isSortable: true,
      disablePadding: true,
      label: "Role",
      align: "left",
      width: "250px",
    },
    {
      id: "roles.subType",
      isSortable: true,
      disablePadding: false,
      label: "Role Subtype",
      align: "left",
    },
    {
      id: "status",
      isSortable: true,
      disablePadding: false,
      label: "Status",
      align: "left",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Action",
      align: "right",
    },
  ];

  const createAuditLog = useCreateAuditlog();

  useEffect(() => {
    if (debouncedInputValue !== "") {
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.USERS,
        option: OPTIONS.USERS_LIST,
        actionCode: ACTION_CODE.WEB_USRS_LIST_SEARCH,
        action: "When searching",
        detail: "Searched within the list of users",
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
      });
    }
  }, [debouncedInputValue]);

  const [openAuditLogModal, setOpenAuditLogModal] = useState(false);

  const handleClick = () => {
    setOpenAuditLogModal(true);
  };

  useAuditLogFirstRender({
    appType: "WEB_BACK_OFFICE",
    module: MODULE_NAME.USERS,
    option: OPTIONS.USERS_LIST,
    actionCode: ACTION_CODE.WEB_USRS_LIST_VIEW,
    action: "When entering",
    detail: "Viewed the list of users",
    transactionDate: new Date(),
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    createdBy: userInfo?._id as string,
  });

  useEffect(() => {
    refetchUsers();
  }, [filters]);

  useEffect(() => {
    setPageTitle("Users");
  }, []);

  return (
    <>
      <div className="w-full overflow-y-auto scroll-smooth">
        <div className="full-width-container mx-auto">
          <div className="flex flex-col items-center justify-start min-h-screen space-y-5">
            <Card sx={{ width: "100%", borderRadius: "8px" }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="headlineSmall" component="h1">
                    Users
                  </Typography>

                  <Box display="flex" gap="0.5rem">
                    <Tooltip title="Audit log">
                      <IconButton
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          border: `1px solid ${theme.palette.primary.dark}`,
                          height: "40px",
                          width: "40px",
                        }}
                        onClick={handleClick}
                      >
                        <HistoryOutlinedIcon
                          sx={{ color: theme.palette.primary.dark }}
                        />
                      </IconButton>
                    </Tooltip>

                    <Button
                      size={"medium"}
                      onClick={() => setShowInviteModal(true)}
                      variant="contained"
                      sx={{
                        borderRadius: "8px",
                        textTransform: "none",
                        textAlign: "center",
                        height: "40px",
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.textMain.light,
                      }}
                      startIcon={<AddIcon />}
                    >
                      ADD
                    </Button>
                  </Box>
                </Box>

                <UserFilters filtersState={filtersState} />

                <DataTable
                  data={rows}
                  headCells={headCells}
                  order={filters.sortOrder === 1 ? "asc" : "desc"}
                  orderBy={filters.sortField}
                  rowsPerPage={filters.pageSize}
                  page={filters.pageNumber}
                  total={users?.totalCount}
                  isLoading={isLoadingUsers}
                  onChangePage={(event, newPage) => {
                    setFilters((filters) => ({
                      ...filters,
                      pageNumber: newPage,
                    }));
                  }}
                  onChangeSize={(event) => {
                    setFilters((filters) => ({
                      ...filters,
                      pageNumber: 0,
                      pageSize: Number(event.target.value),
                    }));
                  }}
                  onChangeSort={(order, orderBy) => {
                    setFilters((filters) => ({
                      ...filters,
                      pageNumber: 0,
                      sortField: orderBy as string,
                      sortOrder: order === "asc" ? 1 : -1,
                    }));
                  }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {genericAlert.open ? (
        <GenericAlertModal
          onCancel={() =>
            setGenericAlert((prevState) => ({ ...prevState, open: false }))
          }
          onClick={() => {
            genericAlert.onConfirm();
          }}
          title={genericAlert.title}
          description={genericAlert.message}
          submitText="Confirm"
          cancelText="Cancel"
          theme={theme}
        />
      ) : null}

      {showInviteModal ? (
        <UserInviteModal
          open={showInviteModal}
          onClose={() => {
            setShowInviteModal(false);
            refetchUsers();
          }}
        />
      ) : null}

      {openEditModal ? (
        <UserEditModal
          open={openEditModal}
          onClose={() => {
            setOpenEditModal(false);
            refetchUsers();
          }}
          editUserId={selectedUser?._id as string}
          userData={{
            firstName: selectedUser?.firstName || "",
            lastName: selectedUser?.lastName || "",
            email: selectedUser?.email || "",
            phoneNumber: selectedUser?.phoneNumber || "",
            externalId: selectedUser?.externalId,
            roles: selectedUser?.roles[0]._id || "",
            orgUnitIds: selectedUser?.orgUnitIds || [],
            programId: selectedUser?.programId || "",
            status: selectedUser?.status,
          }}
        />
      ) : null}

      {openAuditLogModal ? (
        <AuditLogModal
          open={openAuditLogModal}
          module={MODULE_NAME.USERS}
          onClose={() => setOpenAuditLogModal(false)}
        />
      ) : null}
    </>
  );
};

export default UsersPage;
