import React from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import { Circle, KeyboardArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import theme from "@/utils/theme";
import { ParticipantResponse } from "@/domain/participant.interface";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSettings } from "@/hooks/useSetting";
import { GoalStatus } from "@/domain/goal.interface";
import {
  EnrollmentBalance,
  SearchJournalResponse,
} from "@/domain/enrollment.interface";
import { ResponseOfficerPendingAlerts } from "@/domain/alerts.interface";
import { useUserStore } from "@/hooks/useUserStore";

interface ProfileCardsProps {
  participantInfo: ParticipantResponse;
  enrollmentBalance?: EnrollmentBalance;
  alerts?: ResponseOfficerPendingAlerts[];
  searchJournalResponse?: SearchJournalResponse;
}

interface ActionCardProps {
  title: string;
  content?: string;
  showAlert: boolean;
  image: React.ReactNode;
  onClick?: () => void;
}

export const ActionCard = ({
  title,
  image,
  onClick,
  showAlert,
  content,
}: ActionCardProps) => {
  return (
    <Grid item xs={12} sm={4}>
      <Grid
        onClick={onClick}
        item
        container
        direction={"row"}
        sx={{
          bgcolor: () => theme.palette.surface.light2,
          boxShadow: 1,
          borderRadius: 2,
          height: "100%",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
          },
        }}
        xs={12}
      >
        <Grid
          item
          container
          xs={6}
          md
          direction={"column"}
          sx={{ padding: 1.5 }}
        >
          <Grid item>
            <Box>
              {title}
              {showAlert && <Circle sx={{ height: 10 }} color={"error"} />}
            </Box>
          </Grid>
          <Grid item></Grid>
          <Typography variant="h3">{content}</Typography>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          {image}
        </Grid>
        <Grid item xs={1} alignContent={"center"}>
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ActionCards: React.FC<ProfileCardsProps> = ({
  participantInfo,
  enrollmentBalance,
  alerts,
  searchJournalResponse,
}) => {
  const { t } = useTranslation("clients");
  const navigate = useNavigate();
  const participantId = useParams().participantId;
  const { data: settings } = useGetSettings();
  const { lastEnrollment: enrollment } = participantInfo;
  const { userHasAccessToCode } = useUserStore();

  return (
    <Grid
      data-testid="action-cards"
      container
      mt={2.5}
      mb={2.5}
      sx={{
        boxShadow: 1,
        borderRadius: 2,
        bgcolor: "background.paper",
      }}
    >
      <Grid
        item
        container
        spacing={1}
        xs
        sm={12}
        padding={3}
        justifyContent={"flex-start"}
      >
        <Grid item xs={12} sm={12}>
          <Typography
            color={theme.palette.textMain.dark2}
            variant="headlineSmall"
          >
            {t("client_profile.client_actions")}
          </Typography>
        </Grid>

        {settings?.journalsModule &&
          userHasAccessToCode("PARTICIPANT_JOURNALS_VIEW") && (
            <ActionCard
              title="Journal entries"
              content={`${searchJournalResponse?.totalCount || 0}`}
              image={
                <img
                  style={{ maxHeight: "96px", maxWidth: "100%" }}
                  src="/assets/actions_journal.svg"
                  alt="journal entries"
                />
              }
              onClick={() =>
                navigate(`/participants/${participantId}/journals`)
              }
              showAlert={(searchJournalResponse?.list || []).some(
                (g) => !g.isViewed
              )}
            />
          )}
        {settings?.goalsModule ? (
          <ActionCard
            showAlert={
              enrollment.goalIds?.length === 0 ||
              (alerts || []).some(
                (g) =>
                  g.code === "PARTICIPANT_STEP_COMPLETION" &&
                  g.participantId === participantId &&
                  !g.isViewed
              )
            }
            title={
              enrollment.goalIds?.length
                ? t("client_profile.actions_goals_completed")
                : t("client_profile.actions_assign_goals")
            }
            content={
              enrollment.goalIds?.length
                ? `${enrollment?.goals?.filter((g) => g.status === GoalStatus.COMPLETED_STEPS).length || 0}/${enrollment?.goalIds?.length}`
                : "-"
            }
            image={
              <img
                style={{ maxHeight: "96px", maxWidth: "100%" }}
                src="/assets/actions_goal.svg"
                alt="journal entries"
              />
            }
            onClick={() => navigate(`/participants/${participantId}/goals`)}
          />
        ) : (
          <></>
        )}
        {userHasAccessToCode("PARTICIPANT_REWARDS_VIEW") && (
          <ActionCard
            showAlert={(alerts || []).some(
              (g) =>
                g.code === "PARTICIPANT_REDEEM_REQUEST" &&
                g.participantId === participantId &&
                !g.isViewed
            )}
            title={t("client_profile.actions_current_reward_points")}
            content={enrollmentBalance?.balancePoints + "" || "0"}
            image={
              <img
                style={{ maxHeight: "96px", maxWidth: "100%" }}
                src="/assets/actions_reward.svg"
                alt="reward points"
              />
            }
            onClick={() => navigate(`/participants/${participantId}/rewards`)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ActionCards;
