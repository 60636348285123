import { useState, useEffect, useCallback } from "react";
import { ListChannelMessagesCommand } from "@aws-sdk/client-chime-sdk-messaging";
import { normalizeTimestamp } from "@/utils/dateUtils";
import { MessageModelExtended } from "@/domain/chat.interface";
import { SearchClients } from "@/domain/client.interface";
import { ISettingResponse } from "@/domain/setting.interface";
import { useChatMessagesContext } from "@/providers/ChatContext";
import { useMessagingClient } from "./useMessagingClient";

export interface UseChatMessagesProps {
  contacts: SearchClients[];
  userInfo: { _id: string; chimeInstanceUserArn: string | undefined };
  settings?: ISettingResponse;
}

export const useChatMessages = ({
  contacts,
  userInfo,
  settings,
}: UseChatMessagesProps) => {
  const { contactMessages, setContactMessages } = useChatMessagesContext();
  const [isLoading, setIsLoading] = useState(false);

  const { messagingClient, isFetching } = useMessagingClient(userInfo._id);

  const fetchAllChannelMessages = useCallback(async () => {
    if (
      !userInfo?.chimeInstanceUserArn ||
      !settings?.chatModule ||
      isLoading ||
      isFetching ||
      !messagingClient
    ) {
      return;
    }

    setIsLoading(true);
    try {
      const fetchPromises = contacts.map(async (contact) => {
        const channelArn = contact?.lastEnrollment?.chatContacts?.find(
          (officer) => officer.officerId === userInfo._id
        )?.channelArn;
        const contactArn = contact?.participant?.chimeInstanceUserArn;

        if (channelArn && userInfo.chimeInstanceUserArn) {
          const response = await messagingClient.send(
            new ListChannelMessagesCommand({
              ChannelArn: channelArn,
              ChimeBearer: userInfo.chimeInstanceUserArn,
              MaxResults: 50,
            })
          );

          const fetchedMessages: MessageModelExtended[] =
            response.ChannelMessages?.reverse().map((message) => ({
              message: message.Content || "",
              sentTime: normalizeTimestamp(message.CreatedTimestamp).toString(),
              sender: message.Sender?.Arn?.split("/").pop() || "Unknown",
              direction:
                message.Sender?.Arn === contactArn ? "incoming" : "outgoing",
              position: "single",
              messageId: message.MessageId || "",
            })) || [];

          setContactMessages((prevMessages) => {
            return {
              ...prevMessages,
              [contactArn]: [...fetchedMessages],
            };
          });
        }
      });

      await Promise.all(fetchPromises);
    } catch (error) {
      console.error("Error fetching messages for all contacts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [
    contacts,
    userInfo?.chimeInstanceUserArn,
    userInfo?._id,
    settings,
    messagingClient,
    isFetching,
  ]);

  useEffect(() => {
    if (contacts.length > 0) {
      fetchAllChannelMessages();
    }
  }, [contacts, fetchAllChannelMessages]);

  return {
    contactMessages,
    isLoading,
    fetchAllChannelMessages,
    setContactMessages,
  };
};
