import { AppVersion } from "@/domain/app-versions.interface";
import { axios } from "@/utils/axios.create";

const getCurrentVersion = async (platform: string): Promise<AppVersion> => {
  return axios
    .get("/app-versions/current?", {
      params: {
        platform,
      },
    })
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(err));
};

export const AppVersionService = {
  getCurrentVersion,
};
