import { AppVersion } from "@/domain/app-versions.interface";
import { AppVersionService } from "@/services/app-versions.service";
import { useQuery } from "@tanstack/react-query";

export const useGetCurrentVersion = (platform: string) => {
  const {
    data,
    isLoading: isLoadingAppVersion,
    isError: isErrorAppVersion,
    refetch: refetchAppVersion,
  } = useQuery<AppVersion, Error>({
    queryKey: ["app-version", platform],
    queryFn: () => AppVersionService.getCurrentVersion(platform),
  });

  return {
    appVersion: data,
    isLoadingAppVersion,
    isErrorAppVersion,
    refetchAppVersion,
  };
};
