import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useMenuStore } from "@/hooks/useMenuStore";
import MenuItemButton from "@/components/MenuItemButton";
import MenuItemIcon from "@/components/MenuItemIcon";
import getIcon from "@/utils/get-icon";
import { Box } from "@mui/material";
import { useClientFilters } from "@/providers/ClientsFiltersContext";
import { ParticipantStatus } from "@/domain/participant.enum";
import theme from "@/utils/theme";

interface MainMenuProps {
  isMobile: boolean;
  handleDrawerClose: () => void;
}

const MainMenu: FC<MainMenuProps> = ({ isMobile, handleDrawerClose }) => {
  const navigate = useNavigate();
  const { menuItems } = useMenuStore();
  const { setFilters, setPersistFilters } = useClientFilters();

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <List>
          {menuItems.map((item) => (
            <div key={item.id}>
              <ListItem disablePadding className="mb-2">
                <MenuItemButton
                  active={item.active}
                  onClick={() => {
                    if (item.type === "link") {
                      if (item.route.startsWith("http")) {
                        window.open(item.route, "_blank");
                      } else {
                        useMenuStore.getState().setActive(item.id);
                        if (item.title === "Clients") {
                          setFilters({
                            sortField: "fullName",
                            sortOrder: 1,
                            programId: "",
                            status: [
                              ParticipantStatus.ACTIVE,
                              ParticipantStatus.COMPLETED,
                            ],
                            search: "",
                            pageNumber: 0,
                            pageSize: 10,
                          });
                          setPersistFilters(false);
                        }
                        navigate(item.route);
                        if (isMobile) handleDrawerClose();
                      }
                    } else {
                      useMenuStore.getState().toggleOpen(item.id);
                    }
                  }}
                >
                  <MenuItemIcon>{getIcon(item.icon)}</MenuItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{ variant: "bodyLarge" }}
                    sx={{
                      color: item.active
                        ? "inherit"
                        : theme.palette.textMain.main3,
                    }}
                  />
                  {item.type === "parent" &&
                    (item.open ? <ExpandLess /> : <ExpandMore />)}
                </MenuItemButton>
              </ListItem>
              {item.children && (
                <Collapse
                  key={`collapse-${item.id}`}
                  in={item.open}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.children.map((child, i) => (
                      <ListItem
                        key={`${item.id}-${child.id}-${i}`}
                        disablePadding
                      >
                        <MenuItemButton
                          active={child.active}
                          onClick={() => {
                            useMenuStore.getState().setActive(child.id);
                            navigate(child.route);
                            if (isMobile) handleDrawerClose();
                          }}
                        >
                          <MenuItemIcon>{getIcon(child.icon)}</MenuItemIcon>
                          <ListItemText
                            primary={child.title}
                            primaryTypographyProps={{
                              variant: "bodyLarge",
                            }}
                          />
                        </MenuItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default MainMenu;
