import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Slide,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { useParticipantById } from "@/hooks/useParticipants";
import { useParams } from "react-router-dom";
import GoalStepCard from "@/ui/content/clients/goals/GoalStepCard";
import GoalCard from "@/ui/content/clients/goals/GoalCard";
import GoalHeader from "@/ui/content/clients/goals/GoalHeader";
import { useAppTheme } from "@/utils/theme";
import { useTranslation } from "react-i18next";
import { ParticipantResponse } from "@/domain/participant.interface";
import { useGoalsByEnrollment } from "@/hooks/useGoals";
import { GoalTemplateModal } from "@/ui/content/clients/GoalTemplateModal";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import SkeletonLoader from "@/components/SkeletonLoader";
import { GoalStepStatus, Step } from "@/domain/goal.interface";
import ClientStepModal from "@/ui/modals/clients/ClientStepModal";
import { usePageStore } from "@/hooks/usePageStore";
import {
  useGetAlertsByOfficerId,
  useUpdateAlertIsViewed,
} from "@/hooks/useAlerts";

export const goalBoxStyle: SxProps<Theme> = (theme) => ({
  padding: 3,
  boxShadow: 1,
  borderRadius: 2,
  bgcolor: theme.palette.surface.light,
});

interface GoalStepTitleProps {
  children: React.ReactNode;
}

const GoalStepTitle: React.FC<GoalStepTitleProps> = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingBottom: 2.5, minHeight: 60 }}
    >
      {children}
    </Box>
  );
};

export { GoalStepTitle };

const GoalsPage: React.FC = () => {
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { participantId } = useParams();
  const { setPageTitle } = usePageStore();
  const { t } = useTranslation(["clients", "common"]);

  const createAuditLog = useCreateAuditlog();
  const { userInfo } = useUserStore();
  const { data: participantResponse, isLoadingParticipant } =
    useParticipantById(participantId || "");

  const {
    data: goalsData,
    isLoadingGoals,
    refetchGoals,
  } = useGoalsByEnrollment(participantResponse?.lastEnrollment?._id || "");

  const { alerts } = useGetAlertsByOfficerId(userInfo?._id || "", 100, 1);

  const [selectedGoal, setSelectedGoal] = useState<string | null>(null);
  const [slideDirection, setSlideDirection] = useState<
    "left" | "right" | "up" | "down"
  >("left");
  const [isAddGoalModalOpen, setIsAddGoalModalOpen] = useState(false);
  const [showStepModal, setShowStepModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState<Step | null>(null);
  const { mutateAsync: markAsViewed } = useUpdateAlertIsViewed();

  const handleSelectGoal = (goalId: string) => {
    setSlideDirection("right");
    setSelectedGoal(goalId);
  };

  const handleGoBack = () => {
    setSlideDirection("right");
    setSelectedGoal(null);
  };

  const handleOnSelectGoalStep = (stepData: Step) => {
    const matchAlert = alerts?.find(
      (alert) =>
        alert.participantId === participantResponse?.participant?._id &&
        alert.code === "PARTICIPANT_STEP_COMPLETION" &&
        alert.referenceId === stepData._id
    );

    if (matchAlert && !matchAlert.isViewed) {
      markAsViewed({
        alertId: matchAlert._id,
      });
    }
    if (stepData.status === GoalStepStatus.PENDING_SUBMIT) {
      return;
    }

    setSelectedStep(stepData);
    setShowStepModal(true);
  };

  const handleOpenAddGoalModal = () => {
    setIsAddGoalModalOpen(true);
  };
  const handleCloseAddGoalModal = () => {
    setIsAddGoalModalOpen(false);
  };

  const handleOnGoalAdded = async () => {
    handleCloseAddGoalModal();
    await refetchGoals();
  };

  const auditLogViewGoalsCalledRef = React.useRef(false);

  React.useEffect(() => {
    if (
      !isLoadingParticipant &&
      userInfo &&
      !auditLogViewGoalsCalledRef.current
    ) {
      const participantFullName = `${participantResponse?.participant?.firstName} ${participantResponse?.participant?.lastName}`;
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.PARTICIPANT_GOALS,
        option: OPTIONS.PARTICIPANT_GOALS_LIST,
        actionCode: ACTION_CODE.WEB_PART_GOAL_LIST_VIEW,
        action: "When entering to view the list",
        detail: `Viewed the list of goals of ${participantFullName}`,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
        targetId: participantResponse?.participant?._id as string,
        programId: participantResponse?.lastEnrollment.programId,
        orgUnitId: participantResponse?.lastEnrollment.orgUnitId,
      });
      auditLogViewGoalsCalledRef.current = true;
    }
  }, [isLoadingParticipant, userInfo]);

  const selectedGoalData =
    goalsData && goalsData?.find((goal) => goal._id === selectedGoal);

  useEffect(() => {
    setPageTitle(t("goals.page_title"));
  }, []);

  return (
    <div className="w-full overflow-y-auto scroll-smooth">
      <div className="full-width-container mx-auto">
        <div className="flex flex-col items-stretch justify-start min-h-screen">
          <GoalHeader
            isMobile={isMobile}
            selectedGoal={!!selectedGoal}
            onGoBack={handleGoBack}
            headerText={t("goals.goals_available")}
            participantResponse={participantResponse as ParticipantResponse}
            isLoadingParticipant={isLoadingParticipant}
          />

          <Grid container mt={0} spacing={2}>
            <Slide
              direction={slideDirection}
              mountOnEnter
              unmountOnExit
              in={!selectedGoal || !isMobile}
              timeout={250}
            >
              {!isMobile || !selectedGoal ? (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box sx={goalBoxStyle(theme)}>
                    <GoalStepTitle>
                      <Typography
                        color={theme.palette.textMain.dark2}
                        variant="headlineSmall"
                      >
                        {t("goals.goals_available")}
                      </Typography>
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        sx={{
                          borderRadius: 2,
                          fontWeight: 500,
                        }}
                        onClick={handleOpenAddGoalModal}
                      >
                        {t("common:add").toUpperCase()}
                      </Button>
                    </GoalStepTitle>
                    <Grid container>
                      <SkeletonLoader
                        skeletonType="rectangular"
                        isLoading={isLoadingGoals || isLoadingParticipant}
                        skeletonHeight={90}
                        skeletonWidth={400}
                        skeletonCount={3}
                      >
                        {goalsData && goalsData.length > 0 ? (
                          goalsData?.map((goal) => (
                            <Grid item xs={12} key={goal._id}>
                              <GoalCard
                                goalData={goal}
                                isSelected={goal._id === selectedGoal}
                                onSelectGoal={() =>
                                  handleSelectGoal(goal._id || "")
                                }
                              />
                            </Grid>
                          ))
                        ) : (
                          <Typography variant="body1" color="text.secondary">
                            {t("goals.no_goals_added_yet")}
                          </Typography>
                        )}
                      </SkeletonLoader>
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                <Box></Box>
              )}
            </Slide>

            <Slide
              direction="left"
              mountOnEnter
              unmountOnExit
              in={!!selectedGoal}
              timeout={250}
              key={selectedGoal}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Box sx={goalBoxStyle(theme)}>
                  <GoalStepTitle>
                    <Typography
                      color={theme.palette.textMain.dark2}
                      variant="headlineSmall"
                    >
                      {t("goals.steps_for_goal", {
                        goal: selectedGoalData?.name,
                      })}
                    </Typography>
                  </GoalStepTitle>
                  <Grid container>
                    {selectedGoalData?.steps.map((step, index) => (
                      <Grid
                        item
                        xs={12}
                        key={`${selectedGoalData._id}-${step._id}-${index}`}
                      >
                        <GoalStepCard
                          onSelectGoalStep={handleOnSelectGoalStep}
                          stepData={step}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Slide>
          </Grid>

          {isAddGoalModalOpen ? (
            <GoalTemplateModal
              open={isAddGoalModalOpen}
              onCancel={handleCloseAddGoalModal}
              onAdd={handleOnGoalAdded}
              enrollmentId={participantResponse?.lastEnrollment?._id || ""}
            />
          ) : null}

          {showStepModal ? (
            <ClientStepModal
              open={showStepModal}
              onClose={() => setShowStepModal(false)}
              participant={participantResponse as ParticipantResponse}
              goalId={selectedGoal as string}
              stepData={selectedStep as Step}
              refetchGoals={refetchGoals}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GoalsPage;
